import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Image,
    Input,
    Link,
    Show,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue,
  } from "@chakra-ui/react";

import { Line } from "react-chartjs-2";
import React, { useState } from "react";
import { NavBar } from "./";
import { get_predictions_without_login } from "../brain/api.js";
import PredictionsGraph from "./Trade";
import { atom, useAtom } from "jotai";
import { predictionsA, userA } from "../brain/atoms.js";

export function Predictions() {
    // handle the predictions button
    const [isDisabled, setIsDisabled] = useState(false);
    const [lastUpdated, setLastUpdated] = useState(null);
    const [predictions, setPredictions] = useAtom(predictionsA);
    const [allocationSSO, setAllocationSSO] = useState(-1.0);
    const [allocationSPY, setAllocationSPY] = useState(-1.0);
    const [allocationSMH, setAllocationSMH] = useState(-1.0);
    const [allocationVUG, setAllocationVUG] = useState(-1.0);
    const [allocationSPXL, setAllocationSPXL] = useState(-1.0);

    const handleRunScript = async (e) => {
        e.preventDefault();
        setIsDisabled(true);
        const predictions = await get_predictions_without_login();
        setLastUpdated(new Date(predictions.time * 1000));
        setAllocationSSO(predictions.asset_allocation_SSO);
        setAllocationSPY(predictions.asset_allocation_SPY);
        setAllocationSMH(predictions.asset_allocation_SMH);
        setAllocationVUG(predictions.asset_allocation_VUG);
        setAllocationSPXL(predictions.asset_allocation_SPXL);
        delete predictions.time;
        delete predictions.asset_allocation_SSO;
        delete predictions.asset_allocation_SPY;
        delete predictions.asset_allocation_SMH;
        delete predictions.asset_allocation_VUG;
        delete predictions.asset_allocation_SPXL;
        setPredictions(predictions);
        setTimeout(() => {
          setIsDisabled(false); // Enable the button after 3 minutes
        }, 500);
      };
    const buttonColor1 = useColorModeValue("blue.400", "blue.400");

    return (
        <Flex
          minH={"100vh"}
          align={"center"}
          bg="white"
          direction="column"
          // bg="white"
        >
          <NavBar />
          <Flex mx={10} direction={["column", "row"]}>
            <Stack>
              {/* <Stack spacing={2}>
                <Image
                  src="/logo_1.png"
                  style={{
                    display: "block",
                    margin: "0 auto",
                    width: "150px",
                    height: "auto",
                    borderRadius: "50px"
                  }}
                />
              </Stack> */}
              <Image
                // src="/trading_bot.png"
                src="/alphatrader.png"
                style={{
                  display: "block",
                  margin: "0 auto",
                  width: "260px", //150
                  height: "auto",
                  borderRadius: "50px"
                }}
                mb={0}
              />
              {/* <Heading pl={8}>Trade Stocks</Heading> */}
    
              <Box p={8} minW="400px">
                <Stack spacing={4}>
                  <Button
                    onClick={handleRunScript}
                    isDisabled={isDisabled}
                    bg={buttonColor1}
                    color={"white"}
                    _hover={{
                      bg: "blue.500",
                    }}
                    style={{ fontSize: "14px" }}
                    isLoading={isDisabled}
                  >
                    SHOW PREDICTIONS
                  </Button>
    
                </Stack>
                <Stack spacing={4} mt={4}>
                  {lastUpdated && (
                    <h1 id="time" style={{ textAlign: "center" }}>
                      Last updated: {lastUpdated.toLocaleTimeString()}
                    </h1>
                  )}
                  {allocationSSO!=-1.0 && (
                    <h1 id="Allocation Percentage SSO" style={{ textAlign: "center" }}>
                      Allocation SSO: {allocationSSO.toFixed(2)}
                    </h1>
                  )}
                  {/* {allocationSPY!=-1.0 && (
                    <h1 id="Allocation Percentage SPY" style={{ textAlign: "center" }}>
                      Allocation SPY: {allocationSPY.toFixed(2)}
                    </h1>
                  )}
                  {allocationSMH!=-1.0 && (
                    <h1 id="Allocation Percentage SMH" style={{ textAlign: "center" }}>
                      Allocation SMH: {allocationSMH.toFixed(2)}
                    </h1>
                  )}
                  {allocationVUG!=-1.0 && (
                    <h1 id="Allocation Percentage VUG" style={{ textAlign: "center" }}>
                      Allocation VUG: {allocationVUG.toFixed(2)}
                    </h1>
                  )}
                  {allocationSPXL!=-1.0 && (
                    <h1 id="Allocation Percentage SPXL" style={{ textAlign: "center" }}>
                      Allocation SPXL: {allocationSPXL.toFixed(2)}
                    </h1>
                  )} */}
                </Stack>
              </Box>
            </Stack>
            <Show breakpoint="(min-width: 500px)">
              <Divider
                orientation="vertical"
                colorScheme={"blue"}
                height="80vh"
                borderWidth={1.5}
              />
            </Show>
            <PredictionsGraph isDisabled={isDisabled} />
          </Flex>
        </Flex>
      );


}